import React from 'react'
const name = 'auth-webpages'

export default [
  {
    title: '마이사이트',
    path: '/mysites',
    exact: true,
    component: React.lazy(() => import('containers/MySitesContainer')),
    local: name,
    drawer: true
  },
  {
    title: '마이사이트 관리',
    path: '/mysites/:appId',
    exact: true,
    component: React.lazy(() =>
      import('containers/MySitesManagementContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '마이사이트 내 회원 관리',
    path: '/mysites/:appId/members',
    exact: true,
    component: React.lazy(() =>
      import('containers/MySitesManagementContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '마이사이트 계정별 수익금 관리',
    path: '/mysites/:appId/profit',
    exact: true,
    component: React.lazy(() =>
      import('containers/MySitesManagementContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '마이사이트 서브계정 관리',
    path: '/mysites/:appId/subaccounts',
    exact: true,
    component: React.lazy(() =>
      import('containers/MySitesManagementContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '전체 서브계정 사용통계',
    path: '/mysites/:appId/subaccountstat',
    exact: true,
    component: React.lazy(() =>
      import('containers/MySitesManagementContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '서브계정 사용통계',
    path: '/mysites/:appId/subaccountstat/:accountId',
    exact: true,
    component: React.lazy(() =>
      import('containers/MySitesManagementContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '마이사이트 사용통계',
    path: '/mysites/:appId/stat',
    exact: true,
    component: React.lazy(() =>
      import('containers/MySitesManagementContainer')
    ),
    local: name,
    drawer: true
  },
  {
    path: '/balance/estimate',
    title: '결제 금액 견적',
    component: React.lazy(() => import('components/pages/EstimatePage'))
  },
  {
    title: '커머스 자동화 목록',
    path: '/commerce/hooks',
    component: React.lazy(() => import('containers/CommerceHookListContainer'))
  },
  {
    title: '커머스 자동화 설정',
    path: '/commerce/hooks/new',
    exact: true,
    component: React.lazy(() =>
      import('components/pages/CreateCommerceHooksPage')
    )
  },
  {
    title: '커머스 자동화 설정',
    path: '/commerce/hooks/:hookId',
    exact: true,
    component: React.lazy(() =>
      import('components/pages/CreateCommerceHooksPage')
    )
  }
]
