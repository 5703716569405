import React from 'react'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import config from 'config'

const name = 'auth-webpages'
const routes = [
  {
    title: '로그인',
    path: '/login',
    drawer: false,
    publicPage: true,
    component: React.lazy(() => import('components/pages/LoginPage'))
  },
  {
    title: '로그아웃',
    path: '/logout',
    drawer: false,
    publicPage: true,
    component: React.lazy(() => import('containers/LogoutContainer'))
  },
  {
    title: '로그인',
    path: '/oauth2/login',
    drawer: false,
    publicPage: true,
    component: React.lazy(() => import('components/pages/LoginPage'))
  },
  {
    title: '체험하기',
    path: '/oauth2/trial-login',
    drawer: false,
    publicPage: true,
    component: React.lazy(() => import('components/pages/LoginPage'))
  },
  {
    title: '실계정전환',
    path: '/trial/convert',
    component: React.lazy(() => import('containers/ConvertTrialContainer'))
  },
  {
    path: '/oauth2/authorize',
    title: config.pageTitle.approval,
    drawer: false,
    component: React.lazy(() => import('components/pages/AuthorizedPage'))
  },
  {
    path: '/credentials',
    title: config.pageTitle.credentials,
    component: React.lazy(() => import('components/pages/CredentialListPage'))
  },
  {
    title: '메시지 로그',
    path: '/message-log',
    component: React.lazy(() => import('components/pages/MessageLogPage'))
  },
  {
    title: '메시지 로그',
    path: '/message-log/detail',
    component: React.lazy(() => import('components/pages/MessageLogPage'))
  },
  {
    path: '/stat',
    title: config.pageTitle.statistics,
    component: React.lazy(() => import('components/pages/StatisticsPage'))
  },
  {
    path: '/stat/:appId',
    title: config.pageTitle.statistics,
    component: React.lazy(() => import('components/pages/AppStatisticsPage'))
  },
  {
    path: '/stat/:appId/:accountId',
    title: config.pageTitle.statistics,
    component: React.lazy(() => import('components/pages/AppStatisticsPage'))
  },
  {
    path: '/balance',
    title: config.pageTitle.balance,
    component: React.lazy(() => import('components/pages/BalancePage'))
  },
  {
    path: '/balance/history',
    title: '충전 내역',
    component: React.lazy(() => import('containers/RechargeHistoryContainer'))
  },
  {
    path: '/deferred-payment',
    title: '후불 요금',
    component: React.lazy(() => import('containers/DeferredPaymentContainer'))
  },
  {
    path: '/withdrawal/history',
    title: config.pageTitle.withdrawalHistory,
    component: React.lazy(() =>
      import('components/pages/WithdrawalHistoryPage')
    )
  },
  {
    path: '/verify/:hash',
    publicPage: true,
    drawer: false,
    title: config.pageTitle.verifyHash,
    component: React.lazy(() => import('components/pages/MfaVerifyHashPage'))
  },
  {
    path: '/error',
    title: config.pageTitle.error,
    publicPage: true,
    drawer: false,
    component: React.lazy(() => import('components/pages/ErrorPage'))
  },
  {
    title: '사용자 이력',
    path: '/logs',
    component: React.lazy(() => import('components/pages/CoologPage'))
  },
  {
    path: '/storage',
    title: config.pageTitle.storage,
    component: React.lazy(() => import('components/pages/StoragePage'))
  },
  {
    path: '/signature',
    title: config.pageTitle.apiSignature,
    component: React.lazy(() => import('components/pages/APISignaturePage'))
  },
  {
    path: '/quota',
    title: config.pageTitle.quota,
    component: React.lazy(() => import('components/pages/QuotaPage'))
  },
  {
    path: '/unsubscribe',
    title: '차단 해제',
    publicPage: true,
    drawer: false,
    component: React.lazy(() =>
      import('containers/BlockListByReceiverContainer')
    )
  },
  {
    path: '/dashboard',
    title: config.pageTitle.dashboard,
    component: React.lazy(() => import('components/pages/DashboardPage'))
  },
  {
    path: '/webhooks',
    title: config.pageTitle.webhook,
    component: React.lazy(() =>
      import('containers/OutgoingWebhookListContainer')
    )
  },
  {
    title: '비밀번호 재설정',
    path: '/reset-password',
    drawer: false,
    publicPage: true,
    component: React.lazy(() => import('components/pages/ResetPasswordPage'))
  },
  {
    title: '비밀번호 재설정',
    path: '/reset-password/:hashId',
    drawer: false,
    publicPage: true,
    component: React.lazy(() => import('components/pages/ResetPasswordPage'))
  },
  {
    title: '카카오톡 발송 시작하기',
    path: '/kakao/guide',
    publicPage: true,
    component: React.lazy(() => import('components/pages/KakaoGuidePage'))
  },
  {
    title: '카카오톡 발송 시작하기',
    path: '/kakao/guide/:step',
    component: React.lazy(() => import('components/pages/KakaoGuidePage'))
  },
  {
    title: '카카오 채널',
    path: '/kakao/plus-friends',
    component: React.lazy(() => import('components/pages/KakaoPlusFriendPage'))
  },
  {
    title: '카카오 채널',
    path: '/kakao/plus-friends/:pfId',
    component: React.lazy(() => import('components/pages/KakaoPlusFriendPage'))
  },
  {
    title: '카카오 채널 연동',
    path: '/kakao/plus-friends/add',
    component: React.lazy(() =>
      import('components/pages/AddKakaoPlusFriendPage')
    )
  },
  {
    title: '카카오 알림톡 템플릿',
    path: '/kakao/templates',
    component: React.lazy(() => import('components/pages/KakaoTemplatePage'))
  },
  {
    title: '알림톡 템플릿 등록',
    path: '/kakao/templates/new',
    component: React.lazy(() =>
      import('components/pages/CreateKakaoTemplatePage')
    )
  },
  {
    title: '알림톡 템플릿 수정',
    path: '/kakao/templates/:templateId/edit',
    component: React.lazy(() =>
      import('components/pages/CreateKakaoTemplatePage')
    )
  },
  {
    title: '카카오 알림톡 템플릿',
    path: '/kakao/templates/:templateId',
    component: React.lazy(() =>
      import('containers/KakaoTemplateDetailContainer')
    )
  },
  {
    title: '수신 거부 목록',
    path: '/blocklist',
    component: React.lazy(() => import('components/pages/BlockRootPage'))
  },
  {
    title: '발신 차단 목록',
    path: '/block-groups',
    component: React.lazy(() => import('components/pages/BlockRootPage'))
  },
  {
    title: config.pageTitle.authSettings,
    path: '/auth/settings',
    component: React.lazy(() => import('components/pages/AuthSettingsPage'))
  },
  {
    title: config.pageTitle.authHistory,
    path: '/auth/histories',
    component: React.lazy(() => import('components/pages/AuthHistoryPage'))
  },
  {
    title: '인증 페이지',
    path: '/v/:hash',
    publicPage: true,
    component: React.lazy(() => import('components/pages/AuthVerifyHashPage')),
    drawer: false
  },
  // senderids
  {
    title: '발신번호',
    path: '/senderids',
    component: React.lazy(() => import('components/pages/SenderIdPage'))
  },
  {
    title: '발신번호 등록',
    path: '/senderids/registration',
    component: React.lazy(() => import('containers/AddSenderIdContainer'))
  },
  {
    title: '발신번호 재인증 관리',
    path: '/senderids/auth/:phoneNumber',
    component: React.lazy(() =>
      import('containers/SenderIdManagementContainer')
    )
  },
  {
    title: '알림 내역',
    path: '/notifications',
    // component: React.lazy(() => import('containers/NotificationContainer'))
    component: React.lazy(() =>
      import('containers/NotificationHistoryContainer')
    )
  },
  // users
  {
    title: '회원가입',
    drawer: false,
    path: '/signup',
    publicPage: true,
    component: React.lazy(() => import('components/pages/SignUpPage'))
  },
  {
    title: '시작하기',
    path: '/start',
    component: React.lazy(() => import('containers/StartContainer'))
  },
  {
    title: '회원 초대',
    path: '/accept/:invitationId',
    component: React.lazy(() => import('containers/AcceptContainer')),
    drawer: false,
    publicPage: true
  },
  {
    title: '회원정보',
    path: '/profile',
    component: React.lazy(() => import('containers/MemberProfileContainer'))
  },
  {
    title: '계정목록',
    path: '/accounts',
    component: React.lazy(() => import('components/pages/AccountListPage'))
  },
  {
    path: '/accounts/selected-account',
    title: config.pageTitle.accountManagement,
    component: React.lazy(() =>
      import('components/pages/SelectedAccountManagementPage')
    )
  },
  {
    title: '계정탈퇴',
    path: '/leave',
    component: React.lazy(() => import('components/pages/LeavePage'))
  },
  {
    title: config.pageTitle.profitHistoryDetail,
    path: '/me/apps/settle/detail',
    component: React.lazy(() =>
      import('components/pages/ProfitHistoryDetailPage')
    )
  },
  {
    title: config.pageTitle.profitHistory,
    path: '/me/apps/settle',
    component: React.lazy(() => import('containers/ProfitHistoryContainer'))
  },
  {
    title: config.pageTitle.appDetailUserPage,
    path: '/me/apps/:appId',
    component: React.lazy(() => import('containers/AppDetailInfoContainer'))
  },
  {
    title: config.pageTitle.appsMe,
    path: '/me/apps',
    component: React.lazy(() => import('containers/MyAppsContainer'))
  },
  {
    path: '/apps',
    exact: false,
    publicPage: true,
    layout: 'appstore',
    component: React.lazy(() => import('containers/AppListContainer'))
  },
  {
    title: '문자보내기',
    path: '/purplebook',
    exact: true,
    component: React.lazy(() => import('components/pages/PurplebookPage')),
    local: name,
    drawer: true,
    blockServices: {
      solapi: false,
      coolsms: false,
      appHome: false
    }
  },
  {
    title: '문자보내기',
    path: '/purplebook/guide',
    exact: true,
    component: React.lazy(() => import('containers/PurplebookGuideContainer')),
    local: name
  },
  {
    title: '문자보내기',
    path: '/purplebook/:serviceTitle',
    exact: true,
    component: React.lazy(() => import('components/pages/PurplebookPage')),
    local: name,
    drawer: true,
    blockServices: {
      solapi: false,
      coolsms: false,
      appHome: false
    }
  },
  {
    path: '/feature',
    title: '기능 요청',
    component: React.lazy(() => import('containers/FeatureRequestContainer'))
  },
  {
    path: '/feature/:featureId',
    title: '기능 요청',
    component: React.lazy(() =>
      import('containers/FeatureRequestViewerContainer')
    )
  },
  {
    title: 'SDK 다운로드',
    path: '/sdk',
    exact: true,
    component: React.lazy(() => import('containers/SdkListContainer')),
    local: name,
    drawer: true
  },
  {
    title: '네이버톡톡 아이디',
    path: '/naver/agents',
    exact: true,
    component: React.lazy(() => import('containers/NaverAgentContainer')),
    local: name,
    drawer: true
  },
  {
    title: '네이버톡톡 템플릿',
    path: '/naver/templates',
    exact: true,
    component: React.lazy(() => import('containers/NaverTemplateContainer')),
    local: name,
    drawer: true
  },
  {
    title: '네이버톡 템플릿 등록',
    path: '/naver/templates/new',
    component: React.lazy(() =>
      import('containers/CreateNaverTemplateContainer')
    )
  },
  {
    title: '네이버톡 템플릿',
    path: '/naver/templates/:templateId',
    component: React.lazy(() => import('containers/NaverTemplateContainer')),
    local: name,
    drawer: true
  },
  {
    title: '네이버톡 템플릿 수정',
    path: '/naver/templates/:templateId/edit',
    component: React.lazy(() =>
      import('containers/CreateNaverTemplateContainer')
    )
  },
  {
    title: '세금계산서 발행 목록',
    path: '/taxinvoice',
    exact: true,
    component: React.lazy(() => import('components/pages/TaxinvoiceListPage')),
    local: name,
    drawer: true
  },
  {
    title: 'RCS 브랜드 목록',
    path: '/rcs/brands',
    exact: true,
    component: React.lazy(() => import('components/pages/RcsBrandListPage')),
    local: name,
    drawer: true
  },
  {
    title: 'RCS 템플릿 목록',
    path: '/rcs/templates',
    exact: true,
    component: React.lazy(() => import('containers/RcsTemplateListContainer')),
    local: name,
    drawer: true
  },
  {
    title: 'RCS 템플릿 생성',
    path: '/rcs/templates/new',
    exact: true,
    component: React.lazy(() =>
      import('containers/CreateRcsTemplateContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: 'RCS 템플릿 수정',
    path: '/rcs/templates/:templateId/edit',
    exact: true,
    component: React.lazy(() =>
      import('containers/CreateRcsTemplateContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: 'RCS 템플릿 자세히',
    path: '/rcs/templates/:templateId',
    exact: true,
    component: React.lazy(() =>
      import('containers/RcsTemplateDetailContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '후불신청',
    path: '/deferred-payment/request',
    exact: true,
    component: React.lazy(() =>
      import('containers/DeferredPaymentRequestContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '후불신청서류 업로드',
    path: '/deferred-payment/request/upload',
    exact: true,
    component: React.lazy(() =>
      import('containers/DeferredPaymentDocumentUploadContainer')
    ),
    local: name,
    drawer: true
  },
  {
    title: '회원 인증',
    path: '/certificate',
    component: React.lazy(() =>
      import('containers/ProfileCertificateContainer')
    ),
    drawer: false
  },
  {
    title: '2차 인증 진행중',
    path: '/sa-mfa/:mfaKey',
    component: React.lazy(() => import('containers/MfaMultiCertContainer')),
    drawer: false
  },
  {
    title: '',
    path: '/welcome',
    exact: true,
    drawer: false,
    component: React.lazy(() => import('containers/AccountResearchContainer'))
  },
  {
    title: '시작하기',
    path: '/service-tutorial',
    exact: true,
    component: React.lazy(() => import('containers/ServiceTutorialContainer'))
  },
  {
    title: '시작하기',
    path: '/service-tutorial/:stepKey',
    exact: true,
    component: React.lazy(() => import('containers/ServiceTutorialContainer'))
  },
  {
    title: '단축 URL 캠페인 목록',
    path: '/short/campaigns',
    exact: true,
    component: React.lazy(() => import('containers/ShortCampaignListContainer'))
  },
  {
    title: '단축 URL 캠페인',
    path: '/short/campaigns/:campaignId',
    exact: true,
    component: React.lazy(() =>
      import('components/pages/ShortCampaignDetailPage')
    )
  },
  {
    title: '텍스트 비교',
    path: '/compare-text',
    exact: true,
    component: React.lazy(() => import('components/molecules/CompareText'))
  },
  {
    title: 'playground',
    path: '/sandbox',
    exact: true,
    component: React.lazy(() => import('containers/TestContainer'))
  }
]

const AHID = jwt.decode(cookies.get('AHID'))
let currentService = AHID ? 'appHome' : 'solapi'
const isIntegration = process.env.REACT_APP_INTEGRATION === '1'

if (process.env.REACT_APP_ADMIN === '1') {
  const adminRoutes = require('admin/routes')
  routes.push(...adminRoutes.default)
}
if (isIntegration || process.env.REACT_APP_COOLSMS === '1') {
  const coolsmsRoutes = require('./coolsms.js')
  currentService = 'coolsms'
  routes.push(...coolsmsRoutes.default)
}
if (isIntegration || process.env.REACT_APP_COOLSMS !== '1') {
  const solapiRoutes = require('./solapi.js')
  routes.push(...solapiRoutes.default)
}

// default value
const drawer = true
const exact = true

export default routes
  .filter(route => {
    const { blockServices = {} } = route
    if (process.env.NODE_ENV !== 'production' || isIntegration) {
      return true
    }
    return !blockServices[currentService]
  })
  .map(route => {
    if (route.local === undefined) route.local = name
    if (route.drawer === undefined) route.drawer = drawer
    if (route.exact === undefined) route.exact = exact
    return route
  })
