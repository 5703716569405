/**
 * @author EdenCha
 * @email eden@nurigo.net
 * @description 사용자의 서비스 이용 패턴 조사
 */
// import React from 'react'
import { Map } from 'immutable'
import { research as researchAPI } from 'lib/api'
import { createRedux } from 'lib/requestHandler'

const initialState = Map({
  questions: [
    {
      key: 'eodz256n',
      contents: `저희 서비스를 처음 이용하시나요?`
      // yes or no
    },
    {
      key: 'jqpthq68k',
      contents: `사업자인가요? (비영리단체 및 개인사업자 포함)`
      // yes or no
    },
    {
      key: 'o4qyvnaog',
      contents: `주로 어떤 근무 형태로 재직하고 계신가요?`,
      optional: true
      // 대표자, 개발자, 기획자, 마케터, 일반 담당자, 기타
    },
    {
      key: 'woko7kpbi',
      contents: `어떤 형태로 비즈니스를 구성할 계획인가요?`,
      visibleMysite: false,
      visibleCoolsms: false,
      optional: true
      // 별도 고객에게 메시징 서비스를 제공할 예정입니다. -> 앱개발 또는 마이사이트
      // 직접 사용할 메시지 솔루션을 찾고 있습니다. -> API 연동 , 직접발송, 앱 사용
    },
    {
      key: 't2ze68x',
      contents: `메시지 발송은 어떻게 할까요?`,
      visibleMysite: true
      // 홈페이지에서 수동 발송, API/SDK 연동 발송, 외부 앱을 통한 발송, 노코드 자동화 발송, 기타
    },
    {
      key: 'jd67hele3',
      contents: `주로 어떤 종류의 메시지를 발송하고 싶나요?`,
      visibleMysite: true
      // 일반문자, 카카오톡, RCS 문자, 네이버톡톡, 해외
    },
    {
      key: 'uskpr202',
      contents: `발송 내용에 광고를 포함할 예정인가요?`,
      visibleMysite: true
      // yes or no
    },
    {
      key: 'yop2iwsqn',
      contents: `메시지 발송 요금은 어떻게 결제할까요?`,
      visibleMysite: false
      // 선불 / 후불
    },
    {
      key: 'ne8nejf9o',
      contents: `서비스 이용에 관련된 유선 상담을 원하시나요? 영업일 내, 담당자가 직접 연락할 수 있습니다.`,
      visibleMysite: true
      // 선불 / 후불
    }
  ],
  answerGroups: [
    {
      key: '0uo0dc4uw',
      multiple: false,
      choices: [
        { label: '예', value: true },
        { label: '아니오', value: false }
      ]
    },
    {
      key: 'kyts67sgj',
      multiple: true,
      choices: [
        { label: '일반 담당자', value: 'STAFF' },
        { label: '대표자', value: 'OWNER' },
        { label: '마케터', value: 'MARKETER' },
        { label: '개발자', value: 'DEVELOPER' },
        { label: '기획자(PM포함)', value: 'PROJECT_MANAGER' },
        { label: '기타', value: 'ETC' }
      ]
    },
    {
      key: '0q7aw423v',
      multiple: true,
      choices: [
        {
          label: '직접 서비스 이용 예정입니다.',
          value: 'CUSTOMER'
        },
        {
          label: '고객에게 메시징 서비스를 제공할 예정입니다.',
          value: 'PROVIDER',
          helperText:
            '보통 에이전시 업체에 해당합니다. 타사에게 메시징 서비스를 별도로 제공하려는 경우'
        },
        {
          label: '제 계정을 타 업체가 관리할 예정입니다.',
          value: 'SERVICE'
        },
        {
          label: '아직 정해지지 않았습니다.',
          value: '-'
        }
      ]
    },
    {
      key: 'chyrxfjg2',
      multiple: true,
      choices: [
        {
          label: '홈페이지에서 수동 발송',
          value: 'HOMEPAGE',
          helperText:
            '솔라피에서 제공하는 메시지 관리콘솔에서 사람이 직접 필요할 때 메시지를 발송합니다.'
        },
        {
          label: '커머스 자동 발송',
          value: 'COMMERCE',
          visibleCoolsms: false,
          visibleMysite: false,
          helperText:
            '솔라피에서 제공하는 커머스(쇼핑몰) 자동 발송 기능을 사용합니다.'
        },
        {
          label: '내 서비스에서 발송 (API, SDK)',
          value: 'API_SDK',
          helperText:
            '내가 개발한 앱이나 프로그램에서 개발 연동을 통해 자동 발송합니다.'
        },
        {
          label: '외부 서비스 사용',
          value: '3RD_PARTY',
          visibleMysite: false,
          helperText: '타사에서 제공하는 서비스를 통해 메시지를 발송합니다.'
        },
        {
          label: '개발 없는 자동 발송',
          value: 'NO_CODE',
          visibleCoolsms: false,
          visibleMysite: false,
          helperText:
            '코딩이나 개발연동 없이, 특정한 상황에 자동으로 메시지를 발송합니다.'
        },
        { label: '아직 정해지지 않았습니다.', value: '-' }
      ]
    },
    {
      key: 'ztzaidb8i',
      multiple: true,
      choices: [
        {
          label: '일반 문자',
          value: 'TEXT_MESSAGE',
          helperText: '단문, 장문, 사진문자 포함'
        },
        {
          label: '카카오톡',
          value: 'KAKAO',
          helperText:
            '카카오톡 비즈메시지의 경우 알림톡과 친구톡 발송이 가능합니다.'
        },
        { label: 'RCS 문자', value: 'RCS' },
        { label: '네이버 톡톡', value: 'NSA' },
        { label: '국제 문자', value: 'GLOBAL' },
        { label: '이 외 기타', value: 'ETC' }
      ]
    },
    {
      key: 'zhgmzaw53',
      multiple: false,
      choices: [
        { label: '선불', value: 'PREPAIED' },
        { label: '후불', value: 'NOT_PREPAIED' }
      ]
    }
  ]
})

const handler = {}

handler.apiNameHere = {
  // // 모든 옵션은 선택사항
  // options: {
  //   requestName: '요청 이름 입력',
  //   debounce: {
  //     wait: 500,
  //     leading: true,
  //     trailing: false
  //   },
  //   response: {
  //     // 해당 메시지는 컨테이너에 설정이 존재하는 경우 표시 순서가 2순위로 밀려납니다.
  //     successMessage: '요청에 성공했습니다.',
  //     error: {
  //       ignore: [404, 403], // array or true
  //       helper: {
  //         cond: {
  //           status: 404,
  //           errorCode: 'ResourceNotFound'
  //         },
  //         message:
  //           '위 조건에 부합하는 오류 발생 시 메시지에 부가적인 도움말을 사용자에게 보여줄 수 있습니다.'
  //       }
  //     }
  //   }
  // },
  payloadCreator: researchAPI.functionName
}

// handler.apiNameHere = {
//   options: {},
//   reducer: (state, action, options) => {
//     return state.set('key', 'value')
//   }
// }

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
