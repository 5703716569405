/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import qs from 'querystring'
import Joi from 'joi-browser'
import urlParse from 'url-parse'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import removeTrailingSpaces from 'remove-trailing-spaces'

// lib
import bindStateToProps from 'lib/bindStateToProps'
import bindActionCreators from 'lib/bindActionCreators'
import isEqualProps from 'lib/isEqualProps'
import mapActionLog from 'lib/mapActionLog'
import proxyOverride from 'lib/proxyOverride'
import mapAsyncActions from 'lib/mapAsyncActions'
import { isFunction, isEmptyString } from 'lib/detectType'

// redux modules (action)
import { actions as formActions } from 'store/modules/form'
import { actions as oAuth2Actions } from 'store/modules/oauth2'
import { actions as snackbarActions } from 'store/modules/snackbar'
import { actions as usersActions } from 'store/modules/users'
import { actions as coolapiActions } from 'store/modules/coolapi'
import { actions as confirmActions } from 'store/modules/confirm'

// react components
// import PurplebookForeignForm from 'components/organisms/PurplebookForeignForm'
import TrialContainer from 'containers/TrialContainer'
import LoginForm from 'components/organisms/LoginForm'

import config from 'config'

class LoginContainer extends Component {
  constructor(props) {
    super(props)
    autoBind(this)
    mapActionLog(this)
    proxyOverride(this)
    mapAsyncActions(this.props)
    const { location = {} } = this.props
    const { pathname } = location
    const isTrial = /trial-login$/.test(pathname)
    this.state = { isTrial }
  }

  shouldComponentUpdate(nextProps) {
    return !isEqualProps(nextProps, this.props)
  }

  componentDidMount() {
    this.initFormData()
    this.initReCaptcha()
  }

  componentDidUpdate(prevProps) {
    const { formData: prevFormData = {} } = prevProps
    const { formData = {} } = this.props
    const {
      InvalidReCAPTCHA: prevInvalidReCaptcha,
      reCaptchaReady: prevReCaptchaReady
    } = prevFormData
    const { InvalidReCAPTCHA: invalidReCaptcha, reCaptchaReady } = formData
    if (prevReCaptchaReady !== reCaptchaReady && reCaptchaReady === true) {
      this.reCaptchaExecute()
    }
    if (
      prevInvalidReCaptcha !== invalidReCaptcha &&
      invalidReCaptcha === true
    ) {
      this.initReCaptcha()
    }
  }

  initReCaptcha() {
    const siteKey = config.googleRecaptchaSiteKey
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
    document.body.appendChild(script)
    this.reCaptchaInterval = setInterval(this.updateReCaptchaReady, 1000)
  }

  initFormData() {
    const { FormActions, SnackbarActions } = this.props
    const params = qs.parse(this.props.location.search.replace(/^\?/, ''))
    const { returnUrl, message } = params
    if (message) {
      SnackbarActions.new({ key: 'public', message })
    }
    FormActions.init({
      key: 'login',
      pre: false,
      data: {
        returnUrl,
        message,
        isOnlyEnglish: true,
        reCaptchaReady: false,
        invalidReCAPTCHA: undefined
      },
      schema: {
        username: Joi.string()
          .min(1)
          .required()
          .error(new Error('이메일 또는 아이디는 필수 입력입니다.')),
        password: Joi.string()
          .min(1)
          .required()
          .error(new Error('비밀번호는 필수 입력입니다.')),
        captcha: Joi.string()
          .required()
          .error(new Error('로봇 여부를 확인중입니다. 몇 초만 기다려주세요'))
      }
    })
  }

  reCaptchaIsReady() {
    return (
      typeof window !== 'undefined' &&
      typeof window.grecaptcha !== 'undefined' &&
      typeof window.grecaptcha.execute !== 'undefined'
    )
  }

  updateReCaptchaReady() {
    const { FormActions, formData = {} } = this.props
    const { reCaptchaReady } = formData
    if (this.reCaptchaIsReady() && !reCaptchaReady) {
      FormActions.set({
        key: 'login',
        data: { reCaptchaReady: true }
      })
      clearInterval(this.reCaptchaInterval)
    }
  }

  googleReCaptchaVerify = recaptchaToken => {
    const { FormActions } = this.props
    FormActions.set({ key: 'login', data: { captcha: recaptchaToken } })
    return Promise.resolve()
  }

  reCaptchaExecute() {
    return new Promise((resolve, reject) => {
      const action = 'login'
      const siteKey = config.googleRecaptchaSiteKey
      if (this.reCaptchaIsReady() && isFunction(this.googleReCaptchaVerify)) {
        window.grecaptcha
          .execute(siteKey, { action })
          .then(async recaptchaToken => {
            await this.googleReCaptchaVerify(recaptchaToken)
            resolve()
          })
          .catch(reject)
      }
    })
  }

  onHandleClose(event, reason) {
    this.setState({ open: false })
  }

  onKeyUp = e => {
    if (e.keyCode !== 13) return
    e.preventDefault()
    e.stopPropagation()
    this.onSubmitLogin()
  }

  onChangeFormData = data => {
    const { FormActions } = this.props
    FormActions.set({ key: 'login', data })
  }

  onChangePassword = password => {
    const { FormActions } = this.props
    FormActions.set({
      key: 'login',
      data: {
        password,
        isOnlyEnglish: !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(password)
      }
    })
  }

  onClickTrialLogin = async e => {
    e.preventDefault()
    const { history } = this.props
    history.push('/oauth2/trial-login')
  }

  onSubmitLogin = async isAutoRetry => {
    const {
      CoolapiActions,
      SnackbarActions,
      FormActions,
      OAuth2Actions,
      formData = {},
      formError = {},
      updateFlag = {},
      onSuccess
    } = this.props
    const { username, password, returnUrl } = formData
    let captcha = formData.captcha
    const { href } = urlParse(returnUrl)
    if (!updateFlag.username || !updateFlag.password) {
      FormActions.validate({ key: 'login' })
      return
    }
    if (!captcha) {
      captcha = await this.pendingCaptcha()
    }
    if (formError.username || formError.password || !captcha) return
    const params = {
      username: removeTrailingSpaces(username)
        .replace(/\t|\s/g, '')
        .split('\n')
        .join(''),
      password,
      returnUrl: returnUrl ? href : undefined,
      captcha
    }
    try {
      const { data: { returnUrl: resReturnUrl, temp } = {} } =
        await (config.isCoolsms ? CoolapiActions : OAuth2Actions).login(
          omitBy(params, isUndefined),
          { ignore: true }
        )
      if (temp === true) {
        const { UsersActions } = this.props
        await UsersActions.activeDeviceAuth()
      }
      SnackbarActions.clear()
      if (isFunction(onSuccess)) {
        onSuccess()
      } else {
        this.redirect(resReturnUrl)
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errorMessage
      const isReCaptchaMessage = /로봇/.test(errorMessage)
      if (isReCaptchaMessage) {
        if (isAutoRetry !== true) {
          await this.reCaptchaExecute()
          return this.onSubmitLogin(true)
        }
        return this.alertMessage({
          severity: 'warning',
          popupMessage: true,
          title: '페이지 새로고침 후 재시도 해주세요.',
          message:
            '로그인 페이지에 너무 오랜시간 머물렀거나,\n로봇 여부 확인에 실패했습니다.\n같은 문제가 지속되는 경우, 고객센터에 문의해주세요.'
        })
      } else if (!isEmptyString(errorMessage)) {
        this.alertMessage({
          severity: 'warning',
          popupMessage: true,
          message: errorMessage
        })
      }
    }
  }

  redirect(url) {
    const { history } = this.props
    const { origin: currentOrigin } = urlParse(window.location.href, true)
    const { origin, pathname } = urlParse(url, true)
    const token = jwt.decode(cookies.get('CSAK')) || {}
    const isAdmin = /^\/admin/.test(pathname) && token.isAdmin
    const redirectFlag = isAdmin || origin !== currentOrigin
    const isLoginPage = /oauth2\/login/.test(url)
    if (isLoginPage) {
      history.push('/dashboard')
      return
    }
    if (redirectFlag && !isLoginPage) {
      window.location.href = url
    } else {
      history.push(pathname)
    }
  }

  pendingCaptcha = () => {
    const { FormActions } = this.props
    FormActions.set({ key: 'login', data: { captchaLoading: true } })
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        const { formData = {} } = this.props
        const { reCaptchaReady, captcha } = formData
        if (reCaptchaReady === true && captcha) {
          FormActions.set({ key: 'login', data: { captchaLoading: false } })
          clearInterval(interval)
          resolve(captcha)
        }
      }, 500)
    })
  }

  alertMessage = params => {
    return new Promise((resolve, reject) => {
      const { ConfirmActions } = this.props
      ConfirmActions.show({
        ...params,
        onConfirm: resolve,
        onCancel: reject
      })
    })
  }

  render() {
    if (this.state.isTrial === true) {
      return <TrialContainer />
    }
    return <LoginForm {...this.props} {...this.state} proxy={this} />
  }
}

export default withRouter(
  connect(
    bindStateToProps({
      form: [
        {
          formData: 'data.login',
          formError: 'error.login',
          updateFlag: 'updateFlag.login'
        }
      ],
      consoleLayout: ['auth']
    }),
    bindActionCreators({
      confirmActions,
      coolapiActions,
      formActions,
      oAuth2Actions,
      snackbarActions,
      usersActions
    })
  )(LoginContainer)
)
