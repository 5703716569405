import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import config from 'config'
import { isObject, isEmptyString } from 'lib/detectType'
import getComponentId from 'lib/getComponentId'

import CommonButton from 'containers/CommonButtonContainer'
import FormHeaderLogo from 'components/molecules/FormHeaderLogo'

const styles = theme => ({
  loginForm: theme.mixins.gutters({
    maxWidth: 320,
    paddingTop: 16,
    paddingBottom: 16
  }),
  buttonWrap: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  loginButtonWrap: {
    position: 'relative'
  },
  botCheckText: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  signUpTextArea: theme.mixins.gutters({
    textAlign: 'center'
  }),
  loginFormLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  passwordField: {
    '& input': {
      '-webkit-ime-mode': 'inactive',
      '-moz-ime-mode': 'inactive',
      '-ms-ime-mode': 'inactive',
      'ime-mode': 'inactive'
    }
  }
})

const LoginPaper = props => {
  const {
    classes,
    onKeyUp,
    onClickTrialLogin,
    onSubmitLogin,
    onChangeFormData,
    onChangePassword,
    isOnlyEnglish = true,
    message,
    formData = {},
    formError,
    isTrial = false
  } = props
  const { captchaLoading = false } = formData
  const ahid = jwt.decode(cookies.get('AHID'))
  const hasHomepageUrl =
    isObject(ahid) && !isEmptyString(ahid?.homepage) && ahid?.homepage
  const appName = isObject(ahid) && ahid?.appName
  const isMysite = hasHomepageUrl && !isEmptyString(appName)
  return (
    <>
      <div className={classes.logoArea}>
        <FormHeaderLogo
          title={`${config?.serviceName} ${isTrial ? '체험하기' : '로그인'}`}
        />
      </div>
      <Typography variant="h6" align="left" color="textSecondary"></Typography>
      {isTrial === false && (
        <>
          <div>
            <TextField
              id="username"
              name="username"
              label={config.isCoolsms ? '이메일 또는 아이디' : '이메일'}
              placeholder={config.isCoolsms ? '이메일 또는 아이디' : '이메일'}
              className={classes.usernameField}
              autoFocus
              fullWidth
              required
              value={formData.username || ''}
              margin="normal"
              onKeyUp={onKeyUp}
              error={formError.username}
              helperText={formError.username}
              onChange={e => onChangeFormData({ username: e.target.value })}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div>
            <TextField
              id="password"
              name="password"
              type="password"
              label="비밀번호"
              placeholder="비밀번호"
              className={classes.passwordField}
              fullWidth
              required
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              helperText={
                (!isOnlyEnglish && '한글로 입력되고 있습니다.') ||
                formError.password
              }
              value={formData.password || ''}
              error={!isOnlyEnglish || formError.password}
              onKeyUp={onKeyUp}
              onChange={e => onChangePassword(e.target.value)}
            />
          </div>
        </>
      )}
      <div className={classes.buttonWrap}>
        <Grid container spacing={1}>
          {message && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {message}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <div
              className={classes.loginButtonWrap}
              id={getComponentId('loginButton')}
            >
              <CommonButton
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                wait={1000}
                clickLoading
                disabled={captchaLoading}
                onClick={() => {
                  isTrial ? onClickTrialLogin() : onSubmitLogin()
                }}
              >
                {isTrial ? '체험하기' : '로그인'}
              </CommonButton>
              <Box
                className={classes.botCheckText}
                style={{ display: captchaLoading ? 'block' : 'none' }}
              >
                <Typography
                  variant="caption"
                  color="textSecondary"
                  align="center"
                  display="block"
                >
                  로봇 여부를 검사하고있습니다.
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  align="center"
                  display="block"
                >
                  응답이 없는 경우 페이지를 새로고침 하세요.
                </Typography>
              </Box>
            </div>
          </Grid>
          {isMysite && (
            <Grid item xs={12}>
              <CommonButton
                variant="outlined"
                size="small"
                color="primary"
                newTabIcon
                fullWidth
                confirm={`현재 웹사이트는 (주)누리고에서 제공하는 메시징 서비스 입니다.\n\n"${appName}" 제공사에서 설정한 외부 홈페이지로 이동할까요?`}
                onClick={() => {
                  window.open(hasHomepageUrl, '_blank')
                }}
              >
                {appName} 홈페이지
              </CommonButton>
            </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.signUpTextArea}>
        <Grid className={classes.formBtnArea} container spacing={1}>
          <Grid item xs={12}>
            {isTrial === true ? (
              <Typography color="primary" align="center" variant="body2">
                <Link to={config.pages.login} className={classes.loginFormLink}>
                  가입된 계정이 존재합니까?
                </Link>
              </Typography>
            ) : (
              <Typography color="primary" align="center" variant="body2">
                <Link
                  to={config.pages.resetPassword}
                  className={classes.loginFormLink}
                >
                  비밀번호를 잊었습니까?
                </Link>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classNames(classes.signUpText, 'signUpText')}
              variant="body2"
            >
              {isTrial === false ? (
                <React.Fragment>
                  처음입니까?{' '}
                  <a
                    href={config.pages.signUp}
                    className={classes.loginFormLink}
                  >
                    회원가입
                  </a>{' '}
                  또는{' '}
                  <a
                    href={config.pages.trial}
                    onClick={onClickTrialLogin}
                    className={classes.loginFormLink}
                  >
                    체험하기
                  </a>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  이미 충분히 체험해 보셨나요?{' '}
                  <a
                    href={config.pages.signUp}
                    className={classes.loginFormLink}
                  >
                    회원가입
                  </a>
                </React.Fragment>
              )}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default withStyles(styles)(LoginPaper)
