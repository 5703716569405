module.exports = {
  'order.chargeAmountPaymentAmount': { label: '충전금 최종 결제 금액' },
  'order.checkoutAccumulationPaymentAmount': {
    label: '네이버페이 적립금 최종 결제 금액'
  },
  'order.generalPaymentAmount': { label: '일반 결제 수단 최종 결제 금액' },
  'order.naverMileagePaymentAmount': {
    label: '네이버페이 포인트 최종 결제 금액'
  },
  'order.orderDate': { label: '주문 일시' },
  'order.orderDiscountAmount': { label: '주문 할인액' },
  'order.orderId': { label: '주문 번호' },
  'order.ordererId': { label: '주문자 ID' },
  'order.ordererName': { label: '주문자 이름' },
  'order.ordererTel': { label: '주문자 연락처' },
  'order.paymentDate': { label: '결제 일시' },
  'order.paymentDueDate': { label: '결제 기한' },
  'order.paymentMeans': { label: '결제 수단' },
  'order.isDeliveryMemoParticularInput': { label: '배송 메모 개별 입력 여부' },
  'order.payLocationType': {
    label: '결제 위치 구분',
    helpText: '(PC / MOBILE)'
  },
  'order.ordererNo': { label: '주문자 번호' },
  'order.payLaterPaymentAmount': { label: '후불 결제 최종 결제 금액' },
  'productOrder.claimStatus': { label: '클레임 상태' },
  'productOrder.claimType': { label: '클레임 구분' },
  'productOrder.decisionDate': { label: '구매 확정일' },
  'productOrder.delayedDispatchDetailedReason': {
    label: '발송 지연 상세 사유'
  },
  'productOrder.delayedDispatchReason': { label: '발송 지연 사유 코드' },
  'productOrder.deliveryDiscountAmount': { label: '배송비 최종 할인액' },
  'productOrder.deliveryFeeAmount': { label: '배송비 합계' },
  'productOrder.deliveryPolicyType': { label: '배송비 정책' },
  'productOrder.expectedDeliveryMethod': { label: '배송 방법 코드' },
  'productOrder.freeGift': { label: '사은품' },
  'productOrder.mallId': { label: '가맹점 ID' },
  'productOrder.optionCode': { label: '옵션 코드' },
  'productOrder.optionPrice': { label: '옵션 금액' },
  'productOrder.packageNumber': { label: '묶음배송 번호' },
  'productOrder.placeOrderDate': { label: '발주 확인일' },
  'productOrder.placeOrderStatus': { label: '발주 상태' },
  'productOrder.productClass': {
    label: '상품 종류',
    helpText: '(일반/추가 상품 구분)'
  },
  'productOrder.productDiscountAmount': { label: '상품별 할인액' },
  'productOrder.productId': { label: '채널 상품 번호' },
  'productOrder.originalProductId': { label: '원상품 번호' },
  'productOrder.merchantChannelId': { label: '채널 번호' },
  'productOrder.productName': { label: '상품명' },
  'productOrder.productOption': { label: '상품 옵션', helpText: '(옵션명)' },
  'productOrder.productOrderId': { label: '상품 주문 번호' },
  'productOrder.productOrderStatus': { label: '상품 주문 상태' },
  'productOrder.quantity': { label: '수량' },
  'productOrder.sectionDeliveryFee': { label: '지역별 추가 배송비' },
  'productOrder.sellerProductCode': {
    label: '판매자 상품 코드',
    helpText: '(판매자가 임의로 지정)'
  },

  'productOrder.shippingAddress.addressType': { label: '수령인 - 배송지 타입' },
  'productOrder.shippingAddress.baseAddress': { label: '수령인 - 기본 주소' },
  'productOrder.shippingAddress.city': { label: '수령인 - 도시' },
  'productOrder.shippingAddress.country': { label: '수령인 - 국가' },
  'productOrder.shippingAddress.detailedAddress': {
    label: '수령인 - 상세 주소'
  },
  'productOrder.shippingAddress.name': { label: '수령인 이름' },
  'productOrder.shippingAddress.state': { label: '수령인 주소 - 주' },
  'productOrder.shippingAddress.tel1': { label: '수령인 - 연락처 1' },
  'productOrder.shippingAddress.tel2': { label: '수령인 - 연락처 2' },
  'productOrder.shippingAddress.zipCode': { label: '수령인 - 우편번호' },
  'productOrder.shippingAddress.isRoadNameAddress': {
    label: '수령인 - 도로명 주소 여부'
  },
  'productOrder.shippingAddress.pickupLocationType': { label: '수령 위치' },
  'productOrder.shippingAddress.pickupLocationContent': {
    label: '수령 위치 상세'
  },
  'productOrder.shippingAddress.entryMethod': { label: '수령인 출입 방법' },
  'productOrder.shippingAddress.entryMethodContent': {
    label: '수령인 출입 방법 상세'
  },

  'productOrder.shippingStartDate': { label: '발송 시작일' },
  'productOrder.shippingDueDate': { label: '발송 기한' },
  'productOrder.shippingFeeType': { label: '배송비 형태(선불/착불/무료)' },
  'productOrder.shippingMemo': { label: '배송 메모' },

  'productOrder.tackingAddress.addressType': { label: '배송지 타입' },
  'productOrder.tackingAddress.baseAddress': { label: '기본 주소' },
  'productOrder.tackingAddress.city': { label: '도시' },
  'productOrder.tackingAddress.country': { label: '국가' },
  'productOrder.tackingAddress.detailedAddress': { label: '상세 주소' },
  'productOrder.tackingAddress.name': { label: '이름' },
  'productOrder.tackingAddress.state': { label: '주' },
  'productOrder.tackingAddress.tel1': { label: '연락처 1' },
  'productOrder.tackingAddress.tel2': { label: '연락처 2' },
  'productOrder.tackingAddress.zipCode': { label: '우편번호' },
  'productOrder.tackingAddress.isRoadNameAddress': {
    label: '도로명 주소 여부'
  },

  'productOrder.totalPaymentAmount': {
    label: '총 결제 금액',
    helpText: '(할인 적용 후 금액)'
  },
  'productOrder.totalProductAmount': {
    label: '상품 주문 금액',
    helpText: '(할인 적용 전 금액)'
  },
  'productOrder.unitPrice': { label: '상품 가격' },
  'productOrder.sellerBurdenDiscountAmount': { label: '판매자 부담 할인액' },
  'productOrder.commissionRatingType': {
    label: '수수료 과금 구분',
    helpText: '(결제 수수료/(구)판매 수수료/채널 수수료)'
  },
  'productOrder.commissionPrePayStatus': { label: '수수료 선결제 상태 구분' },
  'productOrder.paymentCommission': { label: '결제 수수료' },
  'productOrder.saleCommission': { label: '(구)판매 수수료' },
  'productOrder.expectedSettlementAmount': { label: '정산 예정 금액' },
  'productOrder.inflowPath': { label: '유입 경로' },
  'productOrder.inflowPathAdd': { label: '유입 경로 추가 정보' },
  'productOrder.itemNo': {
    label: '아이템 번호',
    helpText:
      '옵션 상품이나 추가 상품 등록 시 자동 생성된 아이템 번호로, 옵션 상품, 추가 상품을 구분하는 고유한 값'
  },
  'productOrder.optionManageCode': {
    label: '옵션 관리 코드',
    helpText:
      '옵션 상품이나 추가 상품 등록 시 판매자가 별도로 입력한 옵션 관리 코드. 옵션 상품이나 추가 상품인 경우에 입력합니다.'
  },
  'productOrder.sellerCustomCode1': {
    label: '판매자 코드 1',
    helpText: '판매자가 내부에서 사용하는 코드 1'
  },
  'productOrder.sellerCustomCode2': {
    label: '판매자 코드 2',
    helpText: '판매자가 내부에서 사용하는 코드 2'
  },
  'productOrder.claimId': { label: '클레임 번호' },
  'productOrder.channelCommission': { label: '채널 수수료' },
  'productOrder.individualCustomUniqueCode': {
    label: '구매자 개인통관고유부호'
  },
  'productOrder.productImediateDiscountAmount': {
    label: '상품별 즉시 할인 금액'
  },
  'productOrder.productProductDiscountAmount': {
    label: '상품별 상품 할인 쿠폰 금액'
  },
  'productOrder.productMultiplePurchaseDiscountAmount': {
    label: '상품별 복수 구매 할인 금액'
  },
  'productOrder.sellerBurdenImediateDiscountAmount': {
    label: '판매자 부담 즉시 할인 금액'
  },
  'productOrder.sellerBurdenProductDiscountAmount': {
    label: '판매자 부담 상품 할인 쿠폰 금액'
  },
  'productOrder.sellerBurdenMultiplePurchaseDiscountAmount': {
    label: '판매자 부담 복수 구매 할인 금액'
  },
  'productOrder.knowledgeShoppingSellingInterlockCommission': {
    label: '네이버 쇼핑 매출 연동 수수료'
  },
  'productOrder.giftReceivingStatus': { label: '선물 수락 상태 구분' },
  'productOrder.sellerBurdenStoreDiscountAmount': {
    label: '판매자 부담 스토어 할인 금액'
  },
  'productOrder.sellerBurdenMultiplePurchaseDiscountType': {
    label: '판매자 부담 복수 구매 할인 타입'
  },
  'productOrder.logisticsCompanyId': { label: '물류사 코드' },
  'productOrder.logisticsCenterId': { label: '물류센터 코드' },
  'productOrder.hopeDelivery.region': { label: '지역' },
  'productOrder.hopeDelivery.additionalFee': {
    label: '배송 희망 지역 설정 배송비'
  },
  'productOrder.hopeDelivery.hopeDeliveryYmd': { label: '배송 희망일' },
  'productOrder.hopeDelivery.hopeDeliveryHm': { label: '배송 희망 시간' },
  'productOrder.hopeDelivery.changeReason': { label: '변경 사유' },
  'productOrder.hopeDelivery.changer': { label: '변경한 사용자' },
  'productOrder.arrivalGuaranteeDate': { label: '배송 도착 보장 일시' },
  'productOrder.deliveryAttributeType': { label: '배송 속성 타입 코드' },

  'delivery.deliveredDate': { label: '배송 완료 일시' },
  'delivery.deliveryCompany': { label: '택배사 코드' },
  'delivery.deliveryMethod': { label: '배송 방법 코드' },
  'delivery.deliveryStatus': { label: '배송 상세 상태' },
  'delivery.isWrongTrackingNumber': { label: '오류 송장 여부' },
  'delivery.pickupDate': { label: '집화 일시' },
  'delivery.sendDate': { label: '발송 일시' },
  'delivery.trackingNumber': { label: '송장 번호' },
  'delivery.wrongTrackingNumberRegisteredDate': {
    label: '오류 송장 등록 일시'
  },
  'delivery.wrongTrackingNumberType': { label: '오류 사유' }
}
