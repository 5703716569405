import { josa } from 'es-hangul'
import findIndex from 'lodash/findIndex'
import { isNumber, isEmptyString } from 'lib/detectType'

const josaList = [
  '이/가',
  '을/를',
  '은/는',
  '으로/로',
  '와/과',
  '이나/나',
  '이에/에',
  '이란/란',
  '아/야',
  '이랑/랑',
  '이에요/예요',
  '으로서/로서',
  '으로써/로써',
  '으로부터/로부터'
]
const numHangul = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']
const alphabetToHangul = alphabet => {
  const alphabetHangul = [
    '에이',
    '비',
    '씨',
    '디',
    '이',
    '에프',
    '쥐',
    '에이치',
    '아이',
    '제이',
    '케이',
    '엘',
    '엠',
    '엔',
    '오',
    '피',
    '큐',
    '알',
    '에스',
    '티',
    '유',
    '브이',
    '더블유',
    '엑스',
    '와이',
    '지'
  ]
  return alphabetHangul?.[alphabet.toUpperCase().charCodeAt(0) - 65]
}

const getLastWord = str => {
  const arr = String(str)
    .replace(/[^가-힣0-9a-zA-Z]/g, '')
    .split('')
  if (arr?.length < 1) {
    return str.slice(-1)
  }
  const lastWord = arr?.[arr?.length - 1]
  if (isNumber(Number(lastWord))) {
    return numHangul?.[Number(lastWord)]
  } else if (/[a-zA-Z]/.test(lastWord)) {
    return alphabetToHangul(lastWord)
  }
  return lastWord
}

// getFullWord : true 이면 조사가 붙은 전체 단어를 반환
export default (str, josaWord, getFullWord = true) => {
  if (isEmptyString(str) || isEmptyString(josaWord)) {
    return str
  }
  const josaIndex = findIndex(josaList, data => {
    return data.split('/').includes(josaWord)
  })
  if (josaIndex === -1) {
    return str
  }
  const selectedJosaArr = josaList?.[josaIndex]
  const lastWord = getLastWord(str)
  const result = josa(lastWord, selectedJosaArr)
  const selectedJosa = result.slice(1, result?.length)
  if (getFullWord === true) {
    return str + selectedJosa
  }
  return selectedJosa
}
