import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { matchPath } from 'react-router'
import autoBind from 'auto-bind'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import deepEqual from 'fast-deep-equal/react'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import omitBy from 'lodash/omitBy'
import uniqBy from 'lodash/uniqBy'
import isUndefined from 'lodash/isUndefined'
import colorConvert from 'color-convert'
import urlParse from 'url-parse'
import qs from 'querystring'
import moment from 'moment-timezone'
import debounce from 'lodash/debounce'

import { MuiThemeProvider } from '@material-ui/core/styles'
import createTheme from '@material-ui/core/styles/createTheme'

import { actions as confirmActions } from 'store/modules/confirm'
import { actions as usersActions } from 'store/modules/users'
import { actions as popupActions } from 'store/modules/popup'
import { actions as snackbarActions } from 'store/modules/snackbar'
import { actions as mfaActions } from 'store/modules/mfa'
import { actions as formActions } from 'store/modules/form'

import { actions as iamActions } from 'store/modules/iam'
import { actions as consoleLayoutActions } from 'store/modules/consoleLayout'
import { actions as verifyMemberEmailActions } from 'store/modules/verifyMemberEmail'
import { actions as deferredPaymentActions } from 'store/modules/deferredPayment'
import { actions as exclusiveAccountActions } from 'store/modules/exclusiveAccount'
import { actions as cashActions } from 'store/modules/cash'
import { actions as quotaActions } from 'store/modules/quota'
import { actions as channelTalkActions } from 'store/modules/channelTalk'
import { actions as notificationActions } from 'store/modules/notification'

import routes from 'routes'
import config from 'config'
import muiTheme from 'themes/nurigo-material'

import logout from 'lib/logout'
import detectCookieChanged from 'lib/detectCookieChanged'
import ReactGA from 'lib/ga'
import ReactGA4 from 'lib/ga4'
import mapActionLog from 'lib/mapActionLog'
import mapAsyncActions from 'lib/mapAsyncActions'
import { bindStateToProps, bindActionCreators } from 'lib'
import {
  isFunction,
  isObject,
  isArray,
  isString,
  isNumber,
  isEmptyObject,
  isEmptyString
} from 'lib/detectType'
import { checkUpdate } from 'lib/version'
import deepOmitByKeys from 'lib/deepOmitByKeys'
// import getServerTime from 'lib/getServerTime'
import setAxiosConfig from 'lib/setAxiosConfig'
import appstoreMuiTheme from 'themes/appstore-theme'

import LoginContainer from 'containers/LoginContainer'
import AccountListContainer from 'containers/AccountListContainer'
import ComponentLoadingBarContainer from 'containers/ComponentLoadingBarContainer'
import MySiteLayoutContainer from 'containers/MySiteLayoutContainer'
import AppstoreLayoutContainer from 'containers/AppstoreLayoutContainer'
import ConsoleLayoutContainer from 'containers/ConsoleLayoutContainer'
import CoolsmsLayoutContainer from 'containers/CoolsmsLayoutContainer'
import PopupContainer from 'containers/PopupContainer'
import ConfirmContainer from 'containers/ConfirmContainer'
import ResponseContainer from 'containers/ResponseContainer'
import SnackbarQueueContainer from 'containers/SnackbarQueueContainer'
// import VerifyMemberCertificateContainer from 'containers/VerifyMemberCertificateContainer'
import ProfileCertificateContainer from 'containers/ProfileCertificateContainer'
import ActivateMemberContainer from 'containers/ActivateMemberContainer'
import VerifyMemberEmploymentContainer from 'containers/VerifyMemberEmploymentContainer'
import UpdatePackageVersionContainer from 'containers/UpdatePackageVersionContainer'
import ComponentGuideContainer from 'containers/ComponentGuideContainer'

import ThankYouForSignUp from 'components/molecules/ThankYouForSignUp'

class LayoutContainer extends Component {
  constructor(props) {
    super(props)
    autoBind(this)
    mapActionLog(this)
    mapAsyncActions(this.props)
    setAxiosConfig({ showSnackbar: this.showSnackbar })
    this.installLayout(props?.location)
    // channel talk member hash
    this.hideTrialMessage = false
    this.hideVerificationMessage = false
    this.memberHash = undefined
    this.memberInfo = undefined
    this.auth = undefined
    this.muiThemeProvider = this.getMuiThemeProvider()
    // 인증 필요 사용자 여부
    this.needCert = false
    // 글로벌 데이터 업데이트 주기
    this.latestGlobalDataUpdateTime = Math.floor(Date.now() / 1000)
    this.props.history.listen(this.onChangePage)
    window.build_hash = process.env.REACT_APP_BUILD_HASH
    detectCookieChanged(this.onChangeCookie, this.intervalRef)
  }

  componentDidMount = async () => {
    try {
      this.initChannelIO()
      this.setSiteMonitoring()
      await this.tokenCheck()
      await this.checkTempToken()
      await this.createAuthState()
      this.getGlobalData({ cache: true })
    } catch (error) {
      this.clearToken()
    }
  }

  shouldComponentUpdate = nextProps => {
    const { auth } = this.props
    const { auth: nextAuth } = nextProps
    this.detectUpdateClient(nextProps)
    const isUpdateAuthData = !deepEqual(auth, nextAuth)
    // auth 정보가 변경 됐거나, 인증 정보 변경 시
    return isUpdateAuthData
  }

  componentDidUpdate = prevProps => {
    const { auth } = this.props
    const { auth: prevAuth } = prevProps
    this.initChannelIO()
    this.setSiteMonitoring()
    const isUpdateAuthData = !deepEqual(auth, prevAuth)
    if (isObject(auth) && !isEmptyObject(auth) && isUpdateAuthData) {
      this.getGlobalData()
    }
  }

  componentWillUnmount = () => {
    this.clearGlobalData()
  }

  installLayout = location => {
    const { ConsoleLayoutActions } = this.props
    this.checkPackageUpdate()
    this.checkMarketerAccountId()
    this.setRouteParams(location)
    this.checkPublicPage(location)
    // close popup
    this.initPopup()
    // set GA
    this.gaPageView()
    // 최근 사용한 메뉴 추가 (솔라피 전용)
    this.visitedMenu(location)
    // this.checkLocalTime()
    ConsoleLayoutActions.initLoadComponent()
  }

  // 업데이트 확인
  checkPackageUpdate = () => {
    const versionData = checkUpdate()
    const { needUpdate, currentVersion, latestVersion } = versionData
    if (needUpdate) {
      this.needUpdate = true
      this.showUpdateSnackbar(currentVersion, latestVersion)
    }
  }

  showUpdateSnackbar = (currentVersion, latestVersion) => {
    const { SnackbarActions } = this.props
    SnackbarActions.new({
      key: 'newVersion',
      severity: 'info',
      message: `새로운 업데이트를 완료했습니다.\n${currentVersion?.version} → ${latestVersion?.version}`,
      buttons: [
        // {
        //   name: '자세히보기',
        //   onClick: () => {
        //     SnackbarActions.dequeue({ hash: 'newVersion' })
        //     this.openPackageUpdatePopup()
        //   }
        // },
        {
          name: '새로고침',
          onClick: () => {
            this.updatePackage()
          }
        }
      ]
    })
  }

  clearGlobalData = () => {
    const {
      UsersActions,
      NotificationActions,
      QuotaActions,
      CashActions,
      ExclusiveAccountActions,
      DeferredPaymentActions
    } = this.props
    UsersActions.clearCurrentLocationResponse()
    NotificationActions.clearCurrentLocationResponse()
    QuotaActions.clearCurrentLocationResponse()
    CashActions.clearCurrentLocationResponse()
    ExclusiveAccountActions.clearCurrentLocationResponse()
    DeferredPaymentActions.clearCurrentLocationResponse()
  }

  getGlobalData = debounce((options = {}) => {
    if (
      this.auth === undefined ||
      isEmptyString(this.memberInfo?.selectedAccountId)
    ) {
      return Promise.resolve()
    }
    this.latestGlobalDataUpdateTime = Math.floor(Date.now() / 1000)
    const { cache = false } = options
    const { NotificationActions, QuotaActions, UsersActions } = this.props
    Promise.all([
      // 잔액정보
      this.getBalanceInfo(),
      // 전용계좌
      this.getExclusiveAccount(cache),
      // 후불정보
      this.getDeferredPayment(cache),
      // 발송한도정보
      QuotaActions.getEstimateQuota({}, { cache }),
      // 읽지 않은 알림 정보
      NotificationActions.getNotifications(
        { status: 'UNREAD', limit: 9 },
        { key: 'getUnreadNotifications' }
      ),
      // 사용자 설정
      UsersActions.getMyPreferences({}, { cache })
    ]).then(() => {
      this.initChannelIO()
    })
  }, 500)

  getExclusiveAccount = async cache => {
    try {
      const { ExclusiveAccountActions } = this.props
      await ExclusiveAccountActions.getBankCode({}, { cache })
      const result = await ExclusiveAccountActions.getExclusiveAccount(
        {},
        { cache }
      )
      return result
    } catch (error) {
      return Promise.resolve()
    }
  }

  getDeferredPayment = async cache => {
    try {
      const { DeferredPaymentActions } = this.props
      const result = await DeferredPaymentActions.get(
        {},
        { key: 'getDeferredPayment', cache }
      )
      return result
    } catch (error) {
      return Promise.resolve()
    }
  }

  getBalanceInfo = async () => {
    const { CashActions } = this.props
    const result = await CashActions.getBalance()
    this.accountPoint = result?.data?.point
    return result
  }

  // 토큰 변경 감지
  onChangeCookie = data => {
    this.detectTokenUpdate(data)
  }

  detectTokenUpdate = async data => {
    if (
      data?.tokenExpired !== true &&
      (this.updateTokenProcessing === true ||
        !isObject(data?.diff) ||
        Object.keys(data?.diff).indexOf('CSAK') === -1)
    ) {
      return
    }
    this.updateTokenProcessing = true
    try {
      const { PopupActions, SnackbarActions } = this.props
      await this.tokenCheck()
      PopupActions.close({ key: 'loginPopup' })
      if (this.token.isTrial !== true) {
        SnackbarActions.dequeue({ hash: 'trialMessage' })
      }
      await this.checkTempToken()
      try {
        this.memberInfo = {}
        await this.createAuthState()
        this.getGlobalData()
      } catch (error) {}
    } catch (error) {
      this.clearToken()
    } finally {
      this.updateTokenProcessing = false
    }
  }

  // 새로운 배포 감지
  detectUpdateClient = nextProps => {
    if (
      this.props.failedToLoadComponent !== nextProps.failedToLoadComponent &&
      this.needUpdate !== true &&
      nextProps.failedToLoadComponent === true
    ) {
      const { SnackbarActions } = this.props
      SnackbarActions.new({
        severity: 'info',
        message:
          '업데이트가 필요한 항목이 있습니다.\n새로고침 후 서비스를 이용해주세요.',
        buttons: [
          {
            name: '새로고침',
            onClick: () => {
              this.updatePackage()
            }
          }
        ]
      })
    }
  }

  // ms clearity 설정
  setSiteMonitoring = async () => {
    if (
      process.env.NODE_ENV !== 'production' ||
      !isEmptyString(this.auth?.targetAccountId)
    ) {
      return
    }
    if (!isFunction(window.clarity)) {
      ;(function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            ;(c[a].q = c[a].q || []).push(arguments)
          }
        t = l.createElement(r)
        t.async = 1
        t.src = 'https://www.clarity.ms/tag/' + i
        y = l.getElementsByTagName(r)[0]
        y.parentNode.insertBefore(t, y)
      })(window, document, 'clarity', 'script', '61fb4nf50u')
      return this.initClarityData()
    }
    this.initClarityData()
  }

  initClarityData = async () => {
    if (!isFunction(window.clarity)) return
    const auth = {}
    try {
      const result = await this.getAuth()
      Object.assign(auth, result)
    } catch (error) {}
    if (isObject(auth) && !isEmptyObject(auth)) {
      const ahid = this.getAhid()
      const appId = ahid?.appId ?? (config.isCoolsms ? 'COOLSMS' : 'SOLAPI')
      window.clarity(
        'identify',
        auth?.memberInfo?.memberId,
        auth?.memberInfo?.selectedAccountId,
        appId
      )
      const isNewbie = String(
        moment(this.auth?.memberInfo?.dateCreated).isAfter(
          moment().subtract(1, 'months')
        )
      )
      window.clarity('set', 'newbie', isNewbie)
    }
    window.clarity('set', 'serviceName', config?.serviceName)
    window.clarity('set', 'packageVersion', process.env.REACT_APP_VERSION)
    window.clarity('set', 'buildHash', process.env.REACT_APP_BUILD_HASH)
  }

  onChangePage = async (location, action) => {
    if (location?.state?.initPage === false) {
      return
    }
    this.installLayout(location)
    try {
      await this.tokenCheck()
      await this.checkTempToken()
      // 아직 계정인증 절차를 밟지 않음
      if (this.auth === undefined) {
        setAxiosConfig({ showSnackbar: this.showSnackbar })
        await this.createAuthState()
      } else {
        // 회원 유효성 검사
        await this.isValidMember()
      }
      if (this.needCert === true) {
        this.openCertificatePopup()
      }
      const currentTime = Math.floor(Date.now() / 1000)
      const updatePeriod = 3
      const needGlobalUpdate =
        currentTime - this.latestGlobalDataUpdateTime > 60 * updatePeriod
      if (needGlobalUpdate === true) {
        this.getGlobalData()
      }
    } catch (error) {
      this.clearToken()
    }
  }

  gaPageView = () => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  initPopup = () => {
    const { PopupActions } = this.props
    PopupActions.setState({ popupData: {} })
  }

  // 페이지 타이틀 설정
  setDocumentTitle = title => {
    document.title = title
      ? `${config.serviceName} - ${title}`
      : config.serviceName
  }

  // 로그인 필요 없는 페이지
  checkPublicPage = location => {
    const { publicPage = false, title } = this.getRouteData(location) || {}
    this.setDocumentTitle(title)
    this.publicPage = publicPage
  }

  // marketerAccountId (추천인 여부)
  checkMarketerAccountId = () => {
    const { mai } = qs.parse(window.location.search.replace(/^\?/, ''))
    if (mai) {
      cookies.set('mai', mai, { expires: 1 })
    }
  }

  visitedMenu = location => {
    if (config?.isCoolsms) return
    const { menus = [] } = this.props
    const menuList = [].concat(...menus.map(menuData => menuData?.list))
    const findMenu = menuList
      .filter(menu => {
        return menu?.admin !== true && menu?.appLayoutOnly !== true
      })
      .find(menu => {
        const { link } = menu
        const match = matchPath(location.pathname, {
          path: link,
          exact: false
        })
        return isObject(match)
      })
    if (isObject(findMenu)) {
      const { name, link, newTab, icon } = findMenu
      const recentVisit =
        JSON.parse(window.localStorage.getItem('recentVisit')) || []
      const newVisitMenuList = uniqBy(
        [
          Object.assign(
            {},
            { name, link, newTab },
            isString(icon) ? { icon } : ''
          )
        ].concat(...recentVisit),
        menu => {
          return menu?.link
        }
      ).slice(0, 6)
      window.localStorage.setItem(
        'recentVisit',
        JSON.stringify(newVisitMenuList)
      )
    }
  }

  setRouteParams = location => {
    const { ConsoleLayoutActions } = this.props
    const routeParams = this.getRouteData(location)
    this.routeParams = routeParams || {}
    ConsoleLayoutActions.setState({ routeParams })
  }

  detectPageMove = (prevProps, props) => {
    const { location: prevLocation } = prevProps
    const { location } = props
    return location.pathname !== prevLocation.pathname
  }

  getRouteData = location => {
    return routes.find(route => {
      const { path, exact } = route
      const match = matchPath(location.pathname, {
        path,
        exact
      })
      return match
    })
  }

  tokenCheck = async () => {
    const token = this.getToken()
    this.token = token
    try {
      const { SnackbarActions } = this.props
      SnackbarActions.dequeue({ hash: 'global' })
      this.checkInvalidToken()
      this.checkExpiredToken()
      this.checkTrialAccount()
      return Promise.resolve(token)
    } catch (error) {
      const ahid = cloneDeep(this.getAhid())
      this.setAuth({ auth: false, token, ahid })
      return Promise.reject()
    }
  }

  // 전역에서 사용되는 auth정보에 데이터를 삽입합니다.
  createAuthState = async () => {
    const { UsersActions } = this.props
    const token = cloneDeep(this.getToken())
    const ahid = cloneDeep(this.getAhid())
    const accountId = token?.accountId
    const verifyCompanyInfo = {}
    const auth = {}
    const initAuthObject = { ahid, token, authLoading: true }
    let role
    this.setAuth({ ...initAuthObject })
    // 토큰에 선택 계정 미존재
    if (isEmptyString(accountId)) {
      this.setAuth({ ...initAuthObject, authLoading: false })
      // 선택된 계정 없는 경우
      if (this.publicPage !== true) {
        this.onOpenSelectAccountPopup()
        return new Promise(() => {})
      }
      return Promise.resolve()
    }
    try {
      // 멤버 상태 조회, 본인인증여부, 휴면여부, 선택 계정 조회
      await this.isValidMember()
      // 채널톡 멤버 해시 값 조회
      this.memberHash = await this.getChannelTalkMemberHash()
      // 현재 마이사이트 appId와 동일한 회원인지 확인
      if (
        (!isEmptyString(ahid?.appId) &&
          ahid?.appId !== this.memberInfo?.appId) ||
        (isEmptyString(ahid?.appId) && !isEmptyString(this.memberInfo?.appId))
      ) {
        throw new Error()
      }
      Object.assign(auth, { memberInfo: { ...this.memberInfo } })
    } catch (error) {
      this.setAuth({ ...initAuthObject, authLoading: false })
      return Promise.reject()
    }
    // 계정 정보 조회
    try {
      const { data: accountInfo } = await UsersActions.getAccountInfo(
        accountId,
        { ga: { label: accountId } }
      )
      this.accountInfo = cloneDeep(accountInfo)
      Object.assign(auth, { ...accountInfo, ...token })
      Object.assign(initAuthObject, { auth })
    } catch (error) {
      this.setAuth({ ...initAuthObject, auth: false, authLoading: false })
      return Promise.reject()
    }
    try {
      // 현재 선택된 계정 정보 (role 조회, 거의 멤버 정보에 가까움)
      const { data = {} } = await UsersActions.getMyAccountInfo({ accountId })
      role = data?.role
      Object.assign(auth, { role })
      Object.assign(initAuthObject, { auth })
    } catch (error) {
      this.setAuth({ ...initAuthObject, authLoading: false })
      return Promise.reject()
    }
    try {
      // 사업자여부 멤버재직인증 검사
      const businessInfo = await this.checkBusinessAccount()
      Object.assign(verifyCompanyInfo, businessInfo)
      Object.assign(auth, token, { verifyCompanyInfo })
      Object.assign(initAuthObject, { auth })
    } catch (error) {
      this.setAuth({ ...initAuthObject, authLoading: false })
      return Promise.reject()
    }
    this.setAuth({ ...initAuthObject, authLoading: false })
    return Promise.resolve()
  }

  setAuth = authObject => {
    const { ConsoleLayoutActions } = this.props
    const result = deepOmitByKeys(authObject?.auth, ['devices', 'logs'])
    this.auth = result
    this.setGtmDataLayer(authObject)
    ConsoleLayoutActions.setState(cloneDeep(authObject))
  }

  setGtmDataLayer = authObject => {
    if (authObject?.authLoading !== false) return
    try {
      const memberInfo = authObject?.auth?.memberInfo
      const certificate = memberInfo?.certificate
      const birthYear = String(certificate?.birth || '').substring(0, 4)
      const gender = certificate?.gender
      const foreigner = certificate?.foreigner
      const isMysite = !isEmptyString(authObject?.ahid)
      // 체험여부
      const isTrial = authObject?.auth?.isTrial
      const serviceName = config?.serviceName
      const accountId = authObject?.auth?.accountId
      const memberId = authObject?.auth?.memberId
      const appId = authObject?.auth?.appId
      const role = authObject?.auth?.role
      const businessName = authObject?.auth?.verifyCompanyInfo?.businessName
      const businessNumber = authObject?.auth?.verifyCompanyInfo?.businessNumber
      const corporationNumber =
        authObject?.auth?.verifyCompanyInfo?.corporationNumber
      // 계정 생성일
      const accountCreatedAt = moment(authObject?.auth?.dateCreated).format(
        'YYYY.MM.DD'
      )
      const accountName = authObject?.auth?.name
      // 멤버 가입일
      const memberCreatedAt = moment(
        authObject?.auth?.memberInfo?.dateCreated
      ).format('YYYY.MM.DD')
      // 실명
      const memberRealName = certificate?.name
      // 첫 본인인증
      const memberCertCreatedAt = moment(certificate?.dateCreated).format(
        'YYYY.MM.DD'
      )
      const businessType = isEmptyString(corporationNumber)
        ? '비사업자'
        : String(corporationNumber).length === 13
        ? '법인사업자'
        : '개인사업자'
      const userInfo = {
        serviceName,
        isMysite,
        isTrial,
        accountId,
        memberId,
        role,
        appId,
        businessType,
        birthYear,
        gender,
        foreigner,
        accountCreatedAt,
        accountName,
        memberCreatedAt,
        memberRealName,
        memberCertCreatedAt,
        businessName,
        businessNumber
      }
      // 사용자 속성
      ReactGA4.gtag('set', 'user_properties', userInfo)
      // 전역 이벤트 매개변수
      ReactGA4.gtag('set', userInfo)
      // gtm data layer push
      ReactGA4.push({ user_info: userInfo })
    } catch (error) {}
  }

  getAuth = async () => {
    if (this.auth === undefined) {
      await this.createAuthState()
    }
    return this.auth
  }

  getToken = () => {
    return jwt.decode(cookies.get('CSAK'))
  }

  getAhid = () => {
    if (config.isCoolsms) return false
    if (this.ahid !== undefined) return this.ahid
    this.ahid = jwt.decode(cookies.get('AHID'))
    if (isObject(this.ahid) && !isEmptyString(this.ahid?.logoUrl)) {
      const logoUrl = this.ahid?.logoUrl
      const urlData = urlParse(logoUrl)
      const pathname = urlData?.pathname
      const [, appId] = pathname.match(/\/(.+?)\//) || []
      Object.assign(this.ahid, { appId })
    }
    return this.ahid
  }

  getChannelTalkMemberHash = async () => {
    try {
      if (isObject(this.getToken())) {
        const { MfaActions } = this.props
        const { data: { hash } = {} } = await MfaActions.getMemberHash()
        return hash
      }
    } catch (error) {}
  }

  checkInvalidToken = () => {
    const token = this.getToken()
    if (!isObject(token) && this.publicPage !== true) {
      this.onOpenLoginPopup()
      throw new Error('토큰이 올바르지 않습니다. 다시 로그인해주세요.')
    }
  }

  checkExpiredToken = () => {
    const token = this.getToken()
    // const serverTime = getServerTime()
    const browserTime = new Date()
    const isExpired = token?.exp < browserTime.getTime() / 1000
    if (isObject(token) && isExpired && this.publicPage !== true) {
      this.onOpenLoginPopup()
      throw new Error('토큰이 만료되었습니다. 다시 로그인해주세요.')
    }
  }

  // 체험하기 계정
  checkTrialAccount = () => {
    const token = this.getToken()
    const isTrial = token?.isTrial
    if (this.hideTrialMessage === true) return
    if (isTrial === true && this.publicPage !== true) {
      const { SnackbarActions, ChannelTalkActions } = this.props
      return SnackbarActions.new({
        key: 'trialMessage',
        message: '현재 체험하기 계정을 이용중입니다.',
        severity: 'info',
        showCloseButton: false,
        buttons: [
          {
            name: '가리기',
            onClick: (e, data) => {
              SnackbarActions.dequeue({ hash: data?.hash })
              this.hideTrialMessage = true
            }
          },
          {
            name: '채팅상담',
            onClick: () => {
              ChannelTalkActions.open({ title: '체험하기' })
            }
          },
          {
            name: '실제계정전환',
            onClick: () => {
              const { history } = this.props
              history.push('/trial/convert')
            }
          }
        ]
      })
    }
  }

  // 디바이스 인증을 받지 않은 임시토큰 여부 확인
  checkTempToken = async () => {
    const { UsersActions, history } = this.props
    const token = this.getToken()
    const { temp } = token
    if (temp === true) {
      const { pathname } = urlParse(window.location.href, true)
      // 성공 시 임시토큰 대신 실제 토큰
      await UsersActions.activeDeviceAuth(
        {},
        { successMessage: '디바이스 인증이 완료되었습니다.' }
      )
      history.push(pathname)
      return Promise.reject()
    }
    return Promise.resolve()
  }

  getBusinessInfo = async () => {
    const { IamActions } = this.props
    const { data = {} } = await IamActions.getBusinessInfo()
    return data
  }

  checkPendingBusinessInfo = async () => {
    const { IamActions } = this.props
    try {
      const { data } = await IamActions.getRequestedBusinessInfo()
      return Promise.resolve(data?.status === 'PENDING')
    } catch (error) {
      return Promise.resolve(false)
    }
  }

  // 사업자 여부 검사 (publicPage에서 사용하지 않음)
  checkBusinessAccount = async () => {
    const result = {}
    try {
      const businessInfo = await this.getBusinessInfo()
      Object.assign(result, businessInfo)
      const isPending = await this.checkPendingBusinessInfo()
      if (isPending && this.hideVerificationMessage === false) {
        const { SnackbarActions } = this.props
        SnackbarActions.new({
          severity: 'info',
          message: '제출한 사업자정보 승인을 기다리고 있습니다..',
          showCloseButton: false,
          buttons: [
            {
              name: '가리기',
              onClick: (e, data) => {
                SnackbarActions.dequeue({ hash: data?.hash })
                this.hideVerificationMessage = true
              }
            }
          ]
        })
      } else {
        const employmentCertificate = await this.noticeEmploymentExpired(
          businessInfo
        )
        Object.assign(result, { employment: employmentCertificate })
      }
      return Promise.resolve(result)
    } catch (error) {
      return Promise.resolve(result)
    }
  }

  // 재직자 인증 경고
  noticeEmploymentExpired = async companyInfo => {
    // 현재 사업자가 등록이 완료되지 않은 경우 표시하지 않음
    if (companyInfo?.status !== 'APPROVED') {
      return Promise.resolve({})
    }
    const employmentCert = {}
    try {
      const { SnackbarActions } = this.props
      const memberInfo = {}
      try {
        const data = await this.getMemberData()
        Object.assign(memberInfo, data)
      } catch (error) {}
      // 멤버 본인인증 여부 검사
      if (memberInfo?.certificate?.status !== 'APPROVED') {
        return Promise.resolve({})
      }
      let message = `${memberInfo?.certificate?.name}님 "${companyInfo?.businessName}" 재직 인증이 필요합니다.`
      const companyMembers = companyInfo?.members || []
      const employmentCertObj =
        companyMembers.find(data => data?.memberId === memberInfo?.memberId) ||
        {}
      if (isObject(employmentCertObj)) {
        Object.assign(employmentCert, employmentCertObj)
      }
      // 재직인증 사용유예기간 여부 (본인이 오너면 유예기간 없음)
      // 해당 필드에 값 존재하면, 상태와 관계없이 APPROVED로 간주함.
      const expiredDate = !isEmptyString(employmentCert?.dateExpired)
        ? moment(employmentCert?.dateExpired)
        : null
      const employmentCertStatus = employmentCert?.status
      // 만료 예정
      const isExpiredSoon =
        expiredDate !== null &&
        employmentCertStatus === 'EXPIRED' &&
        expiredDate.isAfter(moment())
      // 만료됨
      const isExpired =
        expiredDate !== null &&
        employmentCertStatus === 'EXPIRED' &&
        !expiredDate.isAfter(moment())
      if (isExpiredSoon) {
        message += `\n${expiredDate.format(
          'YY.MM.DD HH:mm'
        )}에 인증이 만료될 예정입니다.`
      } else if (isExpired) {
        message += `\n(${expiredDate.format('YY.MM.DD')} 인증 만료됨)`
      }
      const visibleWarningAlert =
        // 만료 예정
        isExpiredSoon
      const visibleErrorAlert =
        // 만료 됐거나,
        isExpired ||
        // 재직인증 신청정보가 없거나
        !isObject(employmentCert) ||
        isEmptyObject(employmentCert) ||
        // 재직인증이 반려된 경우
        employmentCertStatus === 'REJECTED'
      if (visibleWarningAlert) {
        // 기존회원 여부 검사
        const buttons = [
          {
            name: '재직 인증',
            onClick: this.openVerifyEmploymentPopup
          }
        ]
        SnackbarActions.new({
          hash: 'layoutEmploymentCert',
          severity: 'warning',
          message,
          showCloseButton: false,
          buttons
        })
      } else if (visibleErrorAlert) {
        await this.openCertificatePopup()
      }
    } catch (error) {}
    return Promise.resolve(employmentCert)
  }

  openVerifyEmploymentPopup = async isCompanyOwner => {
    const { PopupActions, SnackbarActions } = this.props
    if (isCompanyOwner === true) {
      await this.alertMessage({
        severity: 'info',
        popupMessage: true,
        message:
          '대표자의 경우 재직증명서를 업로드하지 않아도 됩니다. [재직인증 요청하기] 버튼을 클릭하세요.'
      })
    }
    PopupActions.new({
      title: isCompanyOwner ? '대표자인증' : '재직자인증',
      message: (
        <VerifyMemberEmploymentContainer
          onSubmit={() => {
            SnackbarActions.dequeue({ hash: 'layoutEmploymentCert' })
          }}
        />
      )
    })
  }

  // 휴면 해제
  activateMember = () => {
    const { PopupActions, SnackbarActions } = this.props
    if (this.memberInfo?.status !== 'INACTIVE') return Promise.resolve()
    return new Promise((resolve, reject) => {
      PopupActions.new({
        title: '휴면 회원 해제',
        closeButton: false,
        dialogProps: {
          PaperProps: { style: { width: 500 } },
          disableEscapeKeyDown: true
        },
        actions: [
          {
            title: '로그아웃',
            onClick: () => {
              this.onLogout()
            }
          }
        ],
        message: (
          <ActivateMemberContainer
            onActive={memberInfo => {
              SnackbarActions.new({
                severity: 'success',
                message: `${memberInfo?.name}님 재방문을 환영합니다! 휴면상태를 해제했습니다.`
              })
              PopupActions.setPopupData({ key: 'public', openFlag: false })
              resolve()
            }}
          />
        )
      })
    })
  }

  getMemberPerson = async () => {
    try {
      const { IamActions } = this.props
      const { data = {} } = await IamActions.getMemberPerson()
      return data
    } catch (error) {
      return Promise.resolve()
    }
  }

  openCertificatePopup = () => {
    const { PopupActions } = this.props
    const isNewbie = moment(this.memberInfo?.dateCreated).isAfter('2022-03-01')
    const actions = [
      {
        title: '로그아웃',
        onClick: () => {
          this.onLogout()
        }
      }
    ]
    return new Promise((resolve, reject) => {
      PopupActions.new({
        title: '서비스 이용을 위해 인증이 필요합니다.',
        closeButton: !isNewbie,
        actions,
        dialogProps: {
          disableEscapeKeyDown: true,
          disableBackdropClick: true
        },
        onExited: () => {
          // 미인증 시 닫은 경우, 페이지 이동 시 재표기
          if (this.certComplete !== true) {
            this.needCert = true
          }
          resolve()
        },
        closeConfirm: {
          viewCallback: () => true,
          severity: 'warning',
          message: `미인증 시 서비스 이용이 제한됩니다.`
        },
        message: (
          <ProfileCertificateContainer
            onComplete={async () => {
              this.needCert = false
              this.certComplete = true
              PopupActions.close()
              if (isNewbie) {
                await this.chargeNewbiePoint()
              }
              resolve()
            }}
          />
        )
      })
    })
  }

  chargeNewbiePoint = async () => {
    const { PopupActions } = this.props
    let beforePoint = this.accountPoint
    let afterPoint = 0
    try {
      const result = await this.getBalanceInfo()
      afterPoint = result?.data?.point
    } catch (error) {
      return Promise.resolve()
    }
    const isNewAccount = beforePoint === 0 && afterPoint === 300
    if (!isNewAccount) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject) => {
      PopupActions.new({
        title: null,
        supportButton: false,
        closeButton: false,
        buttons: [],
        message: (
          <ThankYouForSignUp
            onClickStartButton={() => {
              PopupActions.close()
              resolve()
            }}
          />
        )
      })
    })
  }

  setMemberData = async () => {
    const { UsersActions } = this.props
    const { data = {} } = await UsersActions.getMemberInfo()
    const certificate = {}
    try {
      const memberPersonData = await this.getMemberPerson()
      Object.assign(certificate, memberPersonData)
    } catch (error) {}
    this.memberInfo = cloneDeep({ ...data, certificate })
    return this.memberInfo
  }

  getMemberData = async () => {
    if (!isObject(this.memberInfo) || isEmptyObject(this.memberInfo)) {
      const memberInfo = await this.setMemberData()
      return memberInfo
    }
    return this.memberInfo
  }

  // 멤버 상태 조회, 인증여부, 휴면여부
  isValidMember = async () => {
    const memberInfo = await this.getMemberData()
    // 휴면 여부
    const isInactiveMember = memberInfo?.status === 'INACTIVE'
    if (isInactiveMember) {
      await this.activateMember()
    }
    // 본인인증 여부
    const isUnverified =
      isObject(memberInfo) &&
      !isEmptyObject(memberInfo) &&
      memberInfo?.certificate?.status !== 'APPROVED' &&
      this.publicPage !== true
    if (isUnverified) {
      await this.openCertificatePopup()
    }
    // 멤버 데이터 재할당
    if (isInactiveMember || isUnverified) {
      await this.setMemberData()
    }
    // 선택된 계정 없는 경우
    if (
      (isEmptyString(memberInfo?.selectedAccountId) ||
        !isNumber(Number(memberInfo?.selectedAccountId))) &&
      this.publicPage !== true
    ) {
      this.onOpenSelectAccountPopup()
      return new Promise(() => {})
    }
    return Promise.resolve()
  }

  showSnackbar = (message, buttonName, moveTo, authFlag) => {
    const { ChannelTalkActions, SnackbarActions, history, auth } = this.props
    if (auth !== false && authFlag === false) {
      this.setAuth({ auth: false })
    }
    return SnackbarActions.new({
      hash: 'global',
      message,
      severity: 'info',
      showCloseButton: false,
      buttons: [
        {
          name: '채팅문의',
          onClick: () => {
            ChannelTalkActions.open()
          }
        },
        {
          name: buttonName,
          onClick: (e, data) => {
            history.push(moveTo)
            SnackbarActions.dequeue({ hash: data?.hash })
          }
        }
      ]
    })
  }

  initChannelIO = debounce(async () => {
    if (!isEmptyString(this.auth?.targetAccountId)) {
      return
    }
    const ahid = this.getAhid() || {}
    const visibleChannelButton = isEmptyObject(ahid)
      ? true
      : ahid?.apphomeConfig?.menuItemsVisibility?.supportButton
    const bootOptions = { visibleChannelButton }
    const params = {
      pluginKey: config.channelTalkPluginKey,
      zIndex: 1400
    }
    try {
      const { ChannelTalkActions } = this.props
      const auth = this.auth
      const memberInfo = this.memberInfo
      const isTrial = auth?.isTrial
      const balance = this.props.getBalance?.data?.balance || 0
      const point = this.props.getBalance?.data?.point || 0
      const useLowBalanceAlert =
        this.props.getBalance?.data?.lowBalanceAlert?.enabled === true
      const useAutoRecharge = this.props.getBalance?.data?.useAutoRecharge !== 0
      const bankCode = this.props.getExclusiveAccount?.data?.bankCode
      const bankList = Object.assign(
        {},
        ...(this.props.getBankCode?.data || [])
      )
      const bankName = bankList?.[bankCode] || '-'
      const exclusiveAccount = `[${bankName}]${
        this.props.getExclusiveAccount?.data?.accountNumber || '-'
      }`
      const isDeferredPaymentUser =
        this.props.getDeferredPayment?.error !== true
      const quota = this.props.getEstimateQuota?.data?.currentQuota || 0
      const remainingQuota =
        quota - (this.props.getEstimateQuota?.data?.today?.maxCount || 0)
      const token = this.getToken()
      const authProfileData = !this.memberHash
        ? {
            accountId: token?.accountId,
            accountName: 'MEMBER_HASH_NOT_FOUND'
          }
        : omitBy(
            {
              name: memberInfo?.certificate?.name || memberInfo?.name,
              selectedAccountId: memberInfo?.selectedAccountId,
              accountId: auth?.accountId || '-',
              accountName: auth?.name || '-',
              memberId: memberInfo?.memberId,
              memberName: memberInfo?.name,
              mobileNumber:
                memberInfo?.certificate?.phone || memberInfo?.phoneNumber,
              birth: memberInfo?.certificate?.birth || '-',
              extraPhoneNumbers: isArray(memberInfo?.extraPhoneNumbers)
                ? memberInfo?.extraPhoneNumbers.join(' ')
                : '-',
              accountStatus: auth?.status || '-',
              memberStatus: memberInfo?.status,
              email: memberInfo?.email,
              isServiceProvider: auth?.isServiceProvider,
              isWhite: auth?.isWhite,
              accountAppId: auth?.appId,
              memberAppId: memberInfo?.appId,
              businessNumber: auth?.verifyCompanyInfo?.businessNumber || '-',
              businessName: auth?.verifyCompanyInfo?.businessName || '-',
              businessStatus: auth?.verifyCompanyInfo?.status,
              ownerName: auth?.verifyCompanyInfo?.ownerName || '-',
              isTrial,
              isVerifiedMemberCert:
                memberInfo?.certificate?.status === 'APPROVED',
              isVerifiedEmployment:
                auth?.verifyCompanyInfo?.employment?.expiredDate ||
                auth?.verifyCompanyInfo?.employment?.status === 'APPROVED',
              accountDateUpdated: auth?.dateUpdated
                ? moment(auth?.dateUpdated).format('YYYY.MM.DD HH:mm:ss')
                : '-',
              memberDateCreated: memberInfo?.dateCreated
                ? moment(memberInfo?.dateCreated).format('YYYY.MM.DD HH:mm:ss')
                : '-',
              balance,
              point,
              useLowBalanceAlert,
              useAutoRecharge,
              exclusiveAccount,
              isDeferredPaymentUser,
              quota,
              remainingQuota
            },
            isUndefined
          )
      const memberId =
        this.memberHash && memberInfo?.memberId
          ? memberInfo?.memberId
          : this.memberHash && auth?.memberId
          ? auth?.memberId
          : undefined
      const consolePageHost = isEmptyString(ahid?.pageHost)
        ? config.consolePageHost
        : ahid?.pageHost
      const commonChannelTalkUserData = {
        packageVersion: process.env.REACT_APP_VERSION,
        buildHash: process.env.REACT_APP_BUILD_HASH,
        serviceName: config?.serviceName,
        consolePageHost,
        guidePageUrl: config?.guidePageUrl,
        supportPageUrl: config?.supportPageHost,
        channelTalkUserDataDateUpdated: moment().format('YYYY.MM.DD HH:mm:ss')
      }
      Object.assign(params, {
        // hideDefaultLauncher: true,
        id: memberId,
        name:
          memberInfo?.certificate?.name || memberInfo?.name || auth?.accountId,
        memberId,
        memberHash: this.memberHash,
        profile: {
          ...commonChannelTalkUserData,
          ...authProfileData
        }
      })
      return ChannelTalkActions.boot(omitBy(params, isUndefined), bootOptions)
    } catch (error) {
      const { ChannelTalkActions } = this.props
      return ChannelTalkActions.boot(omitBy(params, isUndefined), bootOptions)
    }
  }, 500)

  getMuiThemeProvider = () => {
    const { layout } = this.routeParams
    const ahid = this.getAhid()
    const primaryColor = ahid?.apphomeConfig?.primaryColor
    const secondaryColor = ahid?.apphomeConfig?.secondaryColor
    const newTheme = createTheme(
      merge({}, muiTheme, {
        palette: {
          primary: omitBy(
            {
              main: this.isHex(primaryColor) ? primaryColor : undefined,
              dark: this.isHex(primaryColor)
                ? this.getDeepColor(primaryColor)
                : undefined
            },
            isUndefined
          ),
          secondary: omitBy(
            {
              main: this.isHex(secondaryColor) ? secondaryColor : undefined,
              dark: this.isHex(secondaryColor)
                ? this.getDeepColor(secondaryColor)
                : undefined
            },
            isUndefined
          )
        }
      })
    )
    return {
      MuiThemeProvider,
      theme: layout === 'appstore' ? appstoreMuiTheme : newTheme
    }
  }

  isHex = str => {
    const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
    return colorRegex.test(str)
  }

  onOpenLoginPopup = () => {
    const { PopupActions } = this.props
    PopupActions.new({
      key: 'loginPopup',
      padding: false,
      message: <LoginContainer onSuccess={this.onLogin} />,
      actions: [
        {
          title: '로그아웃',
          onClick: () => {
            this.onLogout()
          }
        }
      ],
      dialogProps: {
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        PaperProps: { style: { width: 'auto', paddingTop: 0 } }
      }
    })
  }

  onLogin = async () => {
    const { SnackbarActions } = this.props
    window.location.reload(true)
    SnackbarActions.new({ severity: 'success', message: '로그인 완료' })
  }

  onLogout = () => {
    window.location.href = `/logout`
  }

  clearToken = () => {
    try {
      const {
        ConsoleLayoutActions,
        SnackbarActions,
        PopupActions,
        FormActions,
        MfaActions
      } = this.props
      ConsoleLayoutActions.setState({ auth: false })
      SnackbarActions.dequeue({ hash: 'trialMessage' })
      SnackbarActions.dequeue({ hash: 'layoutEmploymentCert' })
      PopupActions.close({ key: 'public' })
      MfaActions.clear()
      FormActions.clearAll()
      logout()
    } catch (error) {}
  }

  onOpenSelectAccountPopup = () => {
    const { PopupActions } = this.props
    PopupActions.new({
      key: 'loginPopup',
      title: '사용할 계정을 선택하세요.',
      message: <AccountListContainer />,
      closeButton: false,
      actions: [
        {
          title: '로그아웃',
          onClick: () => {
            this.onLogout()
          }
        }
      ],
      dialogProps: {
        onClose: (event, reason) => {},
        disableEscapeKeyDown: true,
        PaperProps: { style: { width: 1200, height: 1000 } }
      }
    })
  }

  getDeepColor(hex) {
    const deepColor = colorConvert.hex.hsl(hex).map((data, index) => {
      const deep = data - 10
      if (index === 1) return data + '%'
      if (index === 2) return (deep < 0 ? 0 : deep) + '%'
      return data
    })
    return `hsl(${deepColor.join(',')})`
  }

  openPackageUpdatePopup = () => {
    const { PopupActions } = this.props
    PopupActions.new({
      title: '새로운 사항',
      onExited: () => {
        this.checkPackageUpdate()
      },
      message: <UpdatePackageVersionContainer />
    })
  }

  updatePackage = () => {
    window.location.reload(true)
  }

  alertMessage = params => {
    const { ConfirmActions } = this.props
    return new Promise((resolve, reject) => {
      ConfirmActions.show({
        ...params,
        onConfirm: resolve,
        onCacnel: reject
      })
    })
  }

  render() {
    const { routeParams = {}, children } = this.props
    const { layout } = routeParams
    const {
      MuiThemeProvider: NewMuiThemeProvider = MuiThemeProvider,
      theme = muiTheme
    } = this.muiThemeProvider
    return (
      <NewMuiThemeProvider theme={theme}>
        <ComponentLoadingBarContainer />
        <ComponentGuideContainer />
        <ResponseContainer />
        <ConfirmContainer confirmKey="public" />
        <PopupContainer popupKey="public" />
        <PopupContainer popupKey="loginPopup" />
        <SnackbarQueueContainer />
        {isObject(this.getAhid()) ? (
          <MySiteLayoutContainer>{children}</MySiteLayoutContainer>
        ) : layout === 'appstore' ? (
          <AppstoreLayoutContainer>{children}</AppstoreLayoutContainer>
        ) : config.isCoolsms ? (
          <CoolsmsLayoutContainer>{children}</CoolsmsLayoutContainer>
        ) : (
          <ConsoleLayoutContainer>{children}</ConsoleLayoutContainer>
        )}
      </NewMuiThemeProvider>
    )
  }
}

export default connect(
  bindStateToProps({
    consoleLayout: ['auth', 'routeParams', 'menus', 'failedToLoadComponent'],
    verifyMemberEmail: [{ getMemberInfo: 'responseData.getMemberInfo' }],
    users: [{ getAccountInfo: 'responseData.getAccountInfo' }],
    iam: [{ getMemberPerson: 'responseData.getMemberPerson' }],

    cash: [{ getBalance: 'responseData.getBalance' }],
    deferredPayment: [
      { getDeferredPayment: 'responseData.getDeferredPayment' }
    ],
    exclusiveAccount: [
      {
        getExclusiveAccount: 'responseData.getExclusiveAccount',
        getBankCode: 'responseData.getBankCode'
      }
    ],
    quota: [{ getEstimateQuota: 'responseData.getEstimateQuota' }]
  }),
  bindActionCreators({
    popupActions,
    confirmActions,
    usersActions,
    snackbarActions,
    iamActions,
    verifyMemberEmailActions,
    consoleLayoutActions,
    deferredPaymentActions,
    exclusiveAccountActions,
    cashActions,
    quotaActions,
    channelTalkActions,
    notificationActions,
    mfaActions,
    formActions
  })
)(withRouter(LayoutContainer))
